import * as actionTypes from '../actions/actionsTypes'

const initialState = {
    orders:[],
    loading:true,
    error:null
}

const reducer = (state = initialState, action)=>{
    switch (action.type){
        case actionTypes.GET_ORDER_START:
            return{
                ...state,
                loading:action.loading,
                error:null
            }

        case actionTypes.GET_ORDER_SUCCESS:
            return{
                ...state,
                orders:action.orders,
                loading:action.loading,
                error:null
            }
            
        case actionTypes.GET_ORDER_FAIL:
            return{
                ...state,
                orders:action.orders,
                error:action.error,
            }
      
        case actionTypes.ADD_ORDER_SUCCESS:
            return{
                ...state,
                orders:action.orders,
                error:null
            }

            default:
            return state;
    }
}

export default reducer;