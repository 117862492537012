import * as actionTypes from '../actions/actionsTypes'

const initialState = {
    products:[],
    hasMore:false,
    loading:true,
    pagenumber:1,
    total:0,
    error:null
}

const reducer = (state = initialState, action)=>{
    switch (action.type){
        case actionTypes.GET_PRODUCTS_START:
            return{
                ...state,
                loading:action.loading,
                error:null
            }

        case actionTypes.GET_PRODUCTS_SUCCESS:
            return{
                ...state,
                products:action.products,
                hasMore:action.hasMore,
                loading:action.loading,
                pagenumber:action.pagenumber,
                total:action.total,
                error:null

            }

            case actionTypes.GET_PRODUCTS_FAIL:
            return{
                ...state,
                products:action.products,
                error:action.error
            }
      
        case actionTypes.ADD_PRODUCT_SUCCESS:
            return{
                ...state,
                products:action.products,
                error:null
            }

            default:
            return state;
    }
}

export default reducer;