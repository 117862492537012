import { combineReducers } from 'redux'

import ProductReducer from './productReducer'
import CatogeryReducer from './reducer_catogery'
import OrderReducer from './reducer_order'
import UserReducer from './reducer_user'

const rootRrducer = combineReducers({
    catogery:CatogeryReducer,
    product:ProductReducer,
    order:OrderReducer,
    user:UserReducer,
})

export default rootRrducer;