import * as actionTypes from '../actions/actionsTypes'

const initialState = {
    user: {},
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;