import * as actionTypes from '../actions/actionsTypes'

const initialState = {
    catogery:[],
    loading:true,
    error:null
}

const reducer = (state = initialState, action)=>{
    switch (action.type){
        case actionTypes.GET_CATOGERY_START:
            return{
                ...state,
                loading:action.loading,
                error:null
            }

        case actionTypes.GET_CATOGERY_SUCCESS:
            return{
                ...state,
                catogery:action.catogery,
                loading:action.loading,
                error:null
            }

            case actionTypes.GET_CATOGERY_FAIL:
            return{
                ...state,
                catogery:action.catogery,
                loading:false,
                error:action.error
            }
      
        case actionTypes.ADD_CATOGERY_SUCCESS:
            return{
                ...state,
                catogery:action.catogery,
                error:null
            }

            default:
            return state;
    }
}

export default reducer;