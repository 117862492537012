import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './style.scss';
import axios from 'axios';
import Routes from './routes/routes'
// import Helmet from 'react-helmet';
import { SERVER_URL } from './constants/serverUrl'

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main:'#388087',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ff0000',
    },
  },
});

function App() {

  axios.defaults.baseURL = SERVER_URL;

 
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {/* <Helmet bodyAttributes={{ style: 'background-color : #1a1a1a' }} /> */}
        <Routes></Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
