import React, { Suspense, lazy } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

const Login = lazy(() => import('../components/Login/Login'));
const SignUp = lazy(() => import('../components/Signup/signup'));
const Report = lazy(() => import('../components/Report/report'));
const SideBar = lazy(() => import('../components/sidemenu/sidemenu'));
// import Dashboard from './dashboard'
// import SignUp from '../components/Signup/signup'
// import SideBar from '../components/sidemenu/sidemenu'
// import Report from '../components/Report/report'

function Routes() {
    return (

        // <Router >
        //     <Switch>
        //         <Route
        //             path="/"
        //             getComponent={(location, callback) => {
        //                 require.ensure([], function (require) {
        //                     callback(null, require('../components/Login/Login.js'));
        //                 });
        //             }}
        //         />
        //         <Route
        //             path="/login"
        //             getComponent={(location, callback) => {
        //                 // Do async things here
        //                 var Login = require('../components/Login/Login');
        //                 callback(null, Login);
        //             }}
        //         />
        //         <Route
        //             path="/signup"
        //             getComponent={(location, callback) => {
        //                 // Do async things here
        //                 var SignUp = require('../components/Signup/signup')

        //                 callback(null, SignUp);
        //             }}
        //         />
        //         <Route
        //             path="/report"
        //             getComponent={(location, callback) => {
        //                 // Do async things here
        //                 var Report = require('../components/Report/report')
        //                 callback(null, Report);
        //             }}
        //         />
        //         <Route
        //             path="/dash"
        //             getComponent={(location, callback) => {
        //                 // Do async things here
        //                 var SideBar = require('../components/sidemenu/sidemenu')

        //                 callback(null, SideBar);
        //             }}
        //         />
        //     </Switch>
        // </Router>



        <Router>
            <Suspense fallback={<div>Loading...</div>}>

                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/signup">
                        <SignUp />
                    </Route>
                    <Route path="/report">
                        <Report />
                    </Route>
                    {/* <Route path="/" >
                    {localStorage.getItem('token') && localStorage.getItem('token') !== undefined ?
                        <Sidemenu />
                        :
                        <Redirect to="/login" />
                    }
                </Route> */}

                    <Route path="/dash" render={() => localStorage.getItem('token') && localStorage.getItem('token') !== undefined ? (<Route component={SideBar} />) : <Redirect to='/login' />} />
                    <Redirect from='*' to='/dash' />

                </Switch>
            </Suspense>
        </Router>

    );
}

export default Routes;
