let env = process.env.NODE_ENV;
let config = {};

config.SERVER_URL = {
  production: "https://gg8gys4n51.execute-api.us-east-1.amazonaws.com/dev",
  // development: "https://gg8gys4n51.execute-api.us-east-1.amazonaws.com/dev",
  // development: "https://gg8gys4n51.execute-api.us-east-1.amazonaws.com/dev",
  // development: "http://localhost:7000/dev",
  development: "http://localhost:4000/dev",
};


export const SERVER_URL = config.SERVER_URL[env];