///////////// MOVIE LIBRARY GET MOVIE
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export const GET_CATOGERY_START = 'GET_CATOGERY_START';
export const GET_CATOGERY_SUCCESS = 'GET_CATOGERY_SUCCESS';
export const GET_CATOGERY_FAIL = 'GET_CATOGERY_FAIL';

export const ADD_CATOGERY_SUCCESS = 'ADD_CATOGERY_SUCCESS';
export const ADD_CATOGERY_FAIL = 'ADD_CATOGERY_FAIL';

export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';